/** @jsx jsx */

import { Global } from '@emotion/core'
import { FC } from 'react'
import { Box, jsx, useColorMode } from 'theme-ui'

import Footer from './footer'

const Layout: FC = ({ children }) => {
  const [colorMode, setColorMode] = useColorMode()
  const color = colorMode === 'default' ? 'default' : 'dark'
  return (
    <Box pl={0}>
      <Global
        styles={theme => `
          ::selection {
            background-color: ${theme.colors.selection};
          }
          
          :focus {
            outline: 0px double ${theme.colors.action};
            outline-offset: 0.125em;
          }
          
          body {
            background: ${theme.colors.background};
            font: 1.25em/${theme.lineHeights.body} ${theme.fonts.body};
          }
          a {
            text-decoration: none;
            color: ${theme.colors.primary};
          }
      `}
      />

      <Box as="main" variant="full">
        {children}
      </Box>
      <Footer />
    </Box>
  )
}

export default Layout
