/** @jsx jsx */

import { FC } from 'react'
import { Box, Flex, jsx, Text, useColorMode, Button, Image, Link, Input, Container, Textarea } from 'theme-ui'

import { Link as LinkItem } from 'gatsby'
import { BrandLogo } from './icon'

const links = [
  { href: `https://twitter.com/@ragefan`, children: `Twitter` },
  { href: `https://github.com/@ragefan`, children: `GitHub` },
  { href: `https://telegram/@ragefan`, children: `Telegram` },
]

const SunIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1.5em"
      width="1.5em"
      {...props}
    >
      <path d="M17 12 A5 5 0 0 1 12 17 A5 5 0 0 1 7 12 A5 5 0 0 1 17 12 z" />
      <path d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />
    </svg>
  )
}

const footerLinks = [
  [
    {
      title: 'Products',
      href: '/products',
    },
    {
      title: 'App',
      href: '/app',
    },
    {
      title: 'Analytics',
      href: '/analytics',
    },
    {
      title: 'Platform',
      href: '/platform',
    },
  ],
  [
    {
      title: 'Developers',
      href: '/dev',
    },
    {
      title: 'Documentation',
      href: '/docs',
    },
    {
      title: 'Github',
      href: 'https://github.com/ragefan',
    },
    {
      title: 'Whitepaper',
      href: '/whitepaper',
    },
    {
      title: 'Audit',
      href: '/audit',
    },
  ],
  [
    {
      title: 'Community',
      href: '/community',
    },
    {
      title: 'Twitter',
      href: 'https://twitter.com/ragefan',
    },
    {
      title: 'Discord',
      href: 'https://discord.com/ragefan',
    },
    {
      title: 'Reddit',
      href: 'https://reddit.com/ragefan',
    },
    {
      title: 'Telegram',
      href: 'https://telegram.com/ragefan',
    },
  ],
  [
    {
      title: 'Info',
      href: '/community',
    },
    {
      title: 'Blog',
      href: 'https://twitter.com/ragefan',
    },
    {
      title: 'FAQ',
      href: 'https://discord.com/ragefan',
    },
    {
      title: 'About',
      href: 'https://reddit.com/ragefan',
    },
    {
      title: 'Community',
      href: 'https://reddit.com/ragefan',
    },
  ],
]

const Footer: FC = () => {
  const [colorMode, setColorMode] = useColorMode()

  return (
    <Box
      as="footer"
      sx={{
        // mt: 5,
        pb: 0,
        // mt: 0,
        color: 'red.0',
        bg: 'grays.0',
        borderTop: 'solid 1px',
        borderColor: 'grays.1',
        opacity: 0.6,
        '&:hover': {
          opacity: 1,
        },
      }}
      p={0}
      m={0}
    >
      <Container>
        <Box sx={{ alignItems: 'flex-start', pt: 5, pb: 6 }}>
          {/* sx={{ px: [4, 0, 0], py: [4, 0, 0] }} */}

          <Flex variant="box.gridly3" sx={{ flexDirection: 'column', pt: 4 }}>
            <Box sx={{ minWidth: '10ch', svg: { opacity: 1, fill: 'grays.9', path: { fill: 'grays.7' } } }}>
              <BrandLogo
                sx={{
                  minWidth: '5ch',
                  height: '1ch',
                }}
              />
              <Box sx={{ flexGrow: 1, pb: 3, pt: 3,textAlign: 'left' }}>
                <Text
                  as="p"
                  sx={{ textAlign: ['center', 'left', 'left'], fontSize: 1, fontWeight: 200, color: 'grays.6' }}
                >
                  &copy; 2021 - Functonary Entertaiment Pvt Ltd
                </Text>
              </Box>
            </Box>

            <Flex
              variant="box.gridly3"
              sx={{
                width: ['100%', '30%', '30%'],
                flexGrow: 2,
                pt: 2,
                ml: 'auto',
                textAlign: ['center', 'center', 'right'],
              }}
            >
              <Box sx={{ display: 'flex', ml: [0, 0, 0] }}>
                {/* <LinkItem as={Link} to="/privacy">
                  Privacy Policy
                </LinkItem>

                <LinkItem as={Link} to="/privacy">
                  <Text sx={{ fontWeight: 300, fontSize: 1, color: 'grays.6', pr: 3 }}>Privacy Policy</Text>
                </LinkItem>
                <LinkItem as={Link} to="/terms">
                  <Text sx={{ fontWeight: 300, fontSize: 1, color: 'grays.6' }}>Terms of Use</Text>
                </LinkItem> */}
              </Box>
              <Box
                sx={{
                  textAlign: 'center',
                  alignItems: 'center',
                  justifyContent: ['center', 'left', 'left'],
                  ml: [0, 0, 'auto'],
                  pb: 2,
                }}
              >
                <Flex sx={{ ml: 'auto', pt: 2, justifyContent: ['center', 'left', 'left'], maxHeight: '48px' }}>
                  <Link href="https://twitter.com/Indelible InkLabs" target="_blank">
                    <Image sx={{ width: '32px', height: '24px' }} src="//s.svgbox.net/social.svg?fill=ddd&ic=twitter" />
                  </Link>
                  <Link href="https://t.me/ragefan" sx={{ ml: 3 }} target="_blank">
                    <Image
                      sx={{ width: '32px', height: '24px', color: 'red' }}
                      src="//s.svgbox.net/social.svg?fill=ddd&ic=telegram"
                    />
                  </Link>
                  <Link href="https://www.reddit.com/user/ragefan" sx={{ ml: 3 }} target="_blank">
                    <Image sx={{ width: '32px', height: '24px' }} src="//s.svgbox.net/social.svg?fill=ddd&ic=reddit" />
                  </Link>
                  <Link href="https://medium.com/ragefanlabs" sx={{ ml: 3 }} target="_blank">
                    <Image sx={{ width: '32px', height: '24px' }} src="//s.svgbox.net/social.svg?fill=ddd&ic=medium" />
                  </Link>
                </Flex>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Container>
    </Box>
  )
}

export default Footer
